.bouncing-loader {
  display: flex;
}

.bouncing-loader > div {
  background-color: black;
  width: 16px;
  height: 16px;
  margin-inline: 6px;
  border-radius: 50%;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-2.5px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
